import React, { useState, useEffect } from "react";
import $ from "jquery";
import Fetcher from "../../Common/Fetch";
function Watermarks() {

    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [settingWatermark, setSettingWatermark] = useState(false);


    const ShowDelayedNotification = (str, isError = false) => {
        window["showDelayedNotification"](str, isError);
    }

    if (isFirstLoad) {
        Fetcher("/ProfileApi/GetWatermarksSettings", {
            method: 'GET', headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(result => setSettingWatermark(result.UpdatedItem.WatermarksEnabled));
        setIsFirstLoad(false)
    }


    let pushSetting = () => {
        Fetcher("/ProfileApi/SaveWatermarksSettings", {
            method: 'POST', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "WatermarksEnabled": settingWatermark })
        }).then(res => res.json())
            .then(res => {
                if (res.Success) {
                    $('#change-watermark-modal').modal('hide');
                    ShowDelayedNotification("Настройки сохранены")
                }
            })
    }

    return (
        <div className="modal fade app-modal" id="change-watermark-modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-width-564 modal-dialog-centered" role="document">
                <div className="modal-content">

                    <button type="button" className="modal-close-btn" data-dismiss="modal" aria-label="Close">
                        <i className="m-icon-timer"></i>
                    </button>
                    <div className="modal-body text-center">
                        <h3 className="section-title mb-3">Водяные знаки</h3>
                        {<p className="paragraph size-14 size-sm-12 opacity-1 mb-3 pb-3">
                            Когда водяные знаки включены, логотип сайта и ваш ник будут автоматически добавляться к вновь загружаемым
                            Вами фотографиям
                        </p>}
                        <div className="input-custom mb-4">
                            <div className="custom-control custom-checkbox app-custom-check mb-2 pb-1 checkbox">
                                <input checked={settingWatermark} onChange={() => settingWatermark ? setSettingWatermark(false) : setSettingWatermark(true)}
                                    type="checkbox" className="custom-control-input" id="selectWatermark" />
                                <label className="custom-control-label" htmlFor="selectWatermark">Включить водяные знаки</label>
                            </div>
                        </div>

                        <button onClick={() => pushSetting()} className="btn btn-primary btn-sm-mobile btn-font-14 btn-lg px-2"
                            aria-label="Close" >
                            {"Сохранить изменения"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Watermarks;
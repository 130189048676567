import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import AccountInfo from "../../Profile/AccountInfo";
import Fetcher from "../../Common/Fetch";
import { PaymentLotStatus } from "../types";


/**
   @interface IPropsType - интерфейс предоставляющий некоторые поля необходимые компоненту PopupBuyNow для его работы. 
   @property lotId - идентификатор лота.
*/

interface IPropsType {
    lotId: number,
    isTradeEnd: false,
    shippingMethod: string
}

/**
   @function PopupBuyNow - функциональный компонет представляющий из себя выпадающий попап, который позволяет произвести оплату лота
   попап открывается при клике на кнопку "Купить сейчас" при просмотре какого-либо лота
*/
function PopupBuyNow(props: IPropsType) {

    // свойство которое хранит в себе шаг покупки, т.е. при шаге 1 показывается одно диалоговое окно, при шаге 2 другое.
    const [step, setStep] = useState(-1);

    // свойство которое хранит в себе данные авторизации на шаге 1
    const [formLogin, setFormLogin] = useState({ email: '', password: '' });

    // метод вызывается при клике по кнопке "Купить сейчас""
    const openStart = () => {
        console.log('PopupBuyNow lotId = ' + props.lotId);
        console.log('AccountInfo.Id = ' + AccountInfo.Id);

        console.log('SHIPPINGMETHOD:' + props.shippingMethod);
        
        console.log(AccountInfo.PaymentLots);

        var isStepPay = false;

        // если пользователь не авторизован
        if (AccountInfo.Id == undefined || AccountInfo.Id == 0) {
            setStep(0);
            console.log('step 0');
            return;
        }
        // проверим в оплаченных лотах пользователя, нет ли данного лота и какой у него статус оплаты
        if (AccountInfo.PaymentLots != undefined) {
            AccountInfo.PaymentLots.forEach(function (pay) {
                console.log(pay);
                if (pay.LotId == props.lotId && pay.StatusId == PaymentLotStatus.PaidComission)
                    isStepPay = true;
            });
        }

        if (isStepPay)
            setStep(3);
        else
            setStep(2);
    }

    // метод вызывается при клике по кнопке "Зарегистрироваться". Сама кнопка располагается в попапе на нулевом шаге 
    const openRegisterForm = () => {
        console.log('openRegisterForm');
        const url = window.location.origin + "/Account/Register";
        console.log(url);
        window.open(url.toString(), '_blank');
    }

    // данный метод обрабатывает ввод в поле email на форме авторизации - шаг 1
    const handleLoginEmailChange = (evnt) => {
        var data = formLogin;
        formLogin.email = evnt.target.value;
        setFormLogin(formLogin);
    }

    // данный метод обрабатывает ввод в поле password на форме авторизации - шаг 1
    const handlePasswordChange = (evnt) => {
        var data = formLogin;
        formLogin.password = evnt.target.value;
        setFormLogin(formLogin);
    }

    // данный метод вызывается при клике по копке "Войти" на форме авторизации в попапе - шаг 1
    const LogIn = () => {
        if (!formLogin.email || !formLogin.password) {
            alert('Пожалуйста, заполните все поля для входа.');
            return;
        }

        //Логика входа
        handleLogin();
    }

    // данный метод выполняет запрос к серверу на атворизацию пользователя
    const handleLogin = async () => {
        const token = await getAntiforgeryToken();
        Fetcher('/auth/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'RequestVerificationToken': token
            },
            body: JSON.stringify({
                UserName: formLogin.email,
                Password: formLogin.password
            })
        })
            .then(response => response.json())
            .then(data => {
                window.location.reload();
            });
    }

    //Получить токен для атворизации пользователя
    const getAntiforgeryToken = async () => {
        const tokenResponse = await Fetcher('/auth/getAntiForgeryToken', {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const token = await tokenResponse.json();
        console.log(JSON.stringify(token));
        return token;
    }

    // метод оплаты комиссии
    const payComission = () => {
        //const url = window.location.origin + "/TradeEditApi/PaymentComission?lotId=" + props.lotId;
        //console.log(url);
        //window.open(url.toString(), '_blank');
        setStep(3);
    }

    // метод оплаты лота
    const payLot = () => {
        //const url = window.location.origin + "/TradeEditApi/PaymentLot?lotId=" + props.lotId;
        //console.log(url);
        //window.open(url.toString(), '_blank');
        setStep(4);
    }

    return (
        <React.Fragment>
            {/** кнопка "Купить сейчас" */}
            <div className="col">
                <button className="btn btn-primary btn-font-14 btn-lg btn-block px-10 btn-sm-mobile"
                    onClick={openStart} style={{ border: 'none' }}>
                    {props.isTradeEnd ? 'Сформировать документы' :  'Купить сейчас' }
                </button>
            </div>
            {/** диалоговые окна */}
            <div className="popup-container" style={{ display: step >= 0 ? 'block' : 'none', overflowY: 'auto', maxHeight: '75vh' }}>
                <div className="close-btn">
                    <button onClick={() => { setStep(-1) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1C2C9C"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                    </button>
                </div>
                {/** диалоговое окно первого шага - просьба авторизоваться если пользователь не авторизован */}
                { (AccountInfo.Id == undefined || AccountInfo.Id == 0) && step == 0 &&
                    <div className="wrap-step wrap-step1">
                        <h1 className="header-text-cl-kk">Внимание: завершающий этап покупки!</h1>
                        <div className="Balls-close">
                            <span className="ball-1"></span>
                            <span className="line-1"></span>
                            <span className="ball-2"></span>
                            <span className="line-2"></span>
                            <span className="ball-3"></span>
                        </div>
                        <div className="step-indicator">
                            <div className="cl-kk bl-1-f-1">
                                <p className="step">шаг 1</p>
                                <p className="text-cl-kk">Авторизация пользователя</p>
                            </div>
                            <div className="cl-kk bl-1-f-2">
                                <p className="step">шаг 2</p>
                                <p className="text-cl-kk">Оплата коммиссии</p>
                            </div>
                            <div className="cl-kk bl-1-f-3">
                                <p className="step">шаг 3</p>
                                <p className="text-cl-kk">Оплата лота</p>
                            </div>
                        </div>
                        <div className="center">
                            <button className="log-in-cl-kk" onClick={() => { setStep(1) }}>Войти</button>
                            <button className="sign-in-cl-kk" onClick={openRegisterForm}>Зарегистрироваться</button>
                        </div>
                        <p className="downblock-text-kk">Пожалуйста, войдите в личный кабинет, чтобы совершить покупку</p>
                    </div>
                }
                {/** диалоговое окно авторизации */}
                {step == 1 &&
                    <div className="wrap-login" >
                        <h2 className="login-header-log-in">ВХОД В ЛИЧНЫЙ КАБИНЕТ</h2>
                        <div className="input-container">
                            <label htmlFor="loginEmail">Логин или Email</label>
                            <input type="text" id="loginEmail" onChange={handleLoginEmailChange} placeholder="Логин или Email" />
                        </div>
                        <div className="input-container">
                            <label htmlFor="password">Пароль</label>
                            <input type="password" id="password" onChange={handlePasswordChange} placeholder="*********" />
                        </div>
                        <button className="log-in-cl-kk-log-in" onClick={LogIn}>Войти в профиль</button>
                        <div className="checkbox-forgot-log-in">
                            <div className="checkbox-div">
                                <input type="checkbox" id="rememberMe" />
                                <label htmlFor="rememberMe">Запомнить меня</label>
                            </div>
                            <a href="#" className="forgot-password-log-in">Забыли пароль?</a>
                        </div>
                    </div>
                }
                {/** диалоговое окно оплата комиссии */}
                {step == 2 &&
                    <div className="wrap-step wrap-step2" >
                        <h1 className="header-text-cl-kk">Оплата коммиссии</h1>
                        <div className="Balls-close">
                            <span className="ball-1 b-1-comp"></span>
                            <span className="line-1 l-1-comp"></span>
                            <span className="ball-2 b-1-comp"></span>
                            <span className="line-2"></span>
                            <span className="ball-3"></span>
                        </div>
                        <div className="step-indicator">
                            <div className="cl-kk bl-1-f-1">
                                <p className="step">шаг 1</p>
                                <p className="text-cl-kk">Авторизация пользователя</p>
                            </div>
                            <div className="cl-kk bl-1-f-2">
                                <p className="step">шаг 2</p>
                                <p className="text-cl-kk">Оплата коммиссии</p>
                            </div>
                            <div className="cl-kk bl-1-f-3">
                                <p className="step">шаг 3</p>
                                <p className="text-cl-kk">Оплата лота</p>
                            </div>
                        </div>
                        <div className="center flex-dircol-gap">
                            <button className="log-in-cl-kk" onClick={payComission}>Оплатить</button> {/* Должна быть платежка (ТАНЯ) */}
                        
                            <p className="text-good-r text-good-pay long-text-good-pay">Вам осталось оплатить комиссию площадки.
                                После оплаты комиссии станет доступна оплата лота.</p>

                            <p className="text-good-r text-good-pay long-text-good-pay">
                            Чтобы завершить покупку, зайдите в приложение своего банка и оплатите два платежных поручения, которые открылись в новых вкладках браузера.
                            Платежные данные находятся в этих поручениях.
                            Покупка будет подтверждена в течение трех рабочих дней после поступления средств.</p>

                        </div>
                    </div>
                }
                
                {/*диалоговое окно оплата лота*/}
                {step == 3 &&
                    <div className="wrap-step wrap-step3">
                        <h1 className="header-text-cl-kk">Оплата лота</h1>
                        <div className="Balls-close">
                            <span className="ball-1 b-1-comp"></span>
                            <span className="line-1 l-1-comp"></span>
                            <span className="ball-2 b-2-comp"></span>
                            <span className="line-2 l-2-comp"></span>
                            <span className="ball-3 b-1-comp"></span>
                        </div>
                        <div className="step-indicator">
                            <div className="cl-kk bl-1-f-1">
                                <p className="step">шаг 1</p>
                                <p className="text-cl-kk">Авторизация пользователя</p>
                            </div>
                            <div className="cl-kk bl-1-f-2">
                                <p className="step">шаг 2</p>
                                <p className="text-cl-kk">Оплата коммиссии</p>
                            </div>
                            <div className="cl-kk bl-1-f-3">
                                <p className="step">шаг 3</p>
                                <p className="text-cl-kk">Оплата лота</p>
                            </div>
                        </div>
                        <div className="center flex-dircol-gap">
                            <button className="log-in-cl-kk" onClick={payLot}>Оплатить</button>
                        </div>
                    </div>
                }

                {/*<div id="cdek-map" style={{ display: (step === 4 && props.shippingMethod == 'Курьерская доставка' ) ? 'block' : 'none' }}></div>*/}
            </div>
        </React.Fragment>
    );
}

export default PopupBuyNow;
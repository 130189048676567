function updateOptions(options) {
    const update = { ...options };
    update.headers = {
        ...update.headers,
        'pragma': 'no-cache',
        'cache-control':'no-cache'
    };
    return update;
}

export default function fetcher(url, options) {
    return fetch(url, updateOptions(options));
}

export function fetcherPOSTJSON(url, data, callback) {
    return fetch(url,
        {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                callback(result);
            }
        )
        .catch((exception) => {
        console.log('catch: ', exception);
        callback(null);
    });
}

export const fetcherPOST = (url, data, callback) => {
    return fetch(url,
        {
            method: 'POST',
            body: data
        })
        .then(res => res.json())
        .then(
            (result) => {
                callback(result);
            }
        )
        .catch((exception) => {
            console.log('catch: ', exception);
            callback(null);
        });
}

export const fetcherGET = (url, callback) => {
    return fetch(url,
        {
            method: 'GET'
        })
        .then(res => res.json())
        .then(
            (result) => {
                callback(result);
            }
        )
        .catch((exception) => {
            console.log('catch: ', exception);
            callback(null);
        });
}

export async function streamToArrayBuffer(stream: ReadableStream<Uint8Array>): Promise<Uint8Array> {
    return new Uint8Array(await new Response(stream).arrayBuffer());
}
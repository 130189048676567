/* eslint-disable prettier/prettier */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import AccountInfo from "./Profile/AccountInfo";
import MyBalance from "./Profile/MyBalance/MyBalance";
import PopupBuyNow from "./Common/LotView/PopupBuyNow";

const CategoryLotsList = React.lazy(() => import("./CategoryLotsList/List"));
const LotsEditor = React.lazy(() => import("./LotsEditor/LotsEditor"));
const MainSearch = React.lazy(() => import("./MainSearch/Index"));
const ProfileDetailsEditor = React.lazy(
  () => import("./Profile/Details/ProfileDetailsEditor"),
);
const VacationDetailsEditor = React.lazy(
  () => import("./Profile/Vacation/VacationDetailsEditor"),
);

const DeliveryAdressDetailsEditor = React.lazy(
  () => import("./Profile/DeliveryAdress/DeliveryAdressDetailsEditor"),
);
const LocationLotDetailsEditor = React.lazy(
  () => import("./Profile/LocationLot/LocationLotDetailsEditor"),
);
const BlackListSellersDetailsEditor = React.lazy(
  () => import("./Profile/BlackList/BlackListSellersDetailsEditor"),
);
const BlackListBuyersDetailsEditor = React.lazy(
  () => import("./Profile/BlackList/BlackListBuyersDetailsEditor"),
);
const StandartDescriptionLotEditor = React.lazy(
  () => import("./Profile/StandertDescriptionLot/StandartDescriptionLotEditor"),
);
const BalanceEditor = React.lazy(
  () => import("./Profile/Balance/BalanceEditor"),
);


const Appeal = React.lazy(() => import("./Profile/Appeal/Appeal"));

const Rate = React.lazy(() => import("./Profile/Rate/Rate"));

const PromotionServices = React.lazy(() => import("./Profile/PromotionServices/PromotionServices"));


const RatingLimit = React.lazy(() => import("./Profile/RatingLimit/RatingLimit"));


const OperatinosHistory = React.lazy(() => import("./Profile/OperatinosHistory/OperatinosHistory"));

const MessageToBuyers = React.lazy(() => import("./Profile/MessageToBuyers/MessageToBuyers"));

const Notifications = React.lazy(
  () => import("./Profile/Notifications/Components/Notifications"),
);

const AccountMessages = React.lazy(() => import("./Profile/Messages"));

const LotDocument = React.lazy(
    () => import("./Common/Document/LotDocument"),
);

const LotWinnerProtocol = React.lazy(
    () => import("./Common/Document/LotWinnerProtocol")
);

const ProfileDocumentsEditor = React.lazy(
  () => import("./Profile/Documents/ProfileDocumentsEditor"),
);
const DocumentsList = React.lazy(() => import("./Common/Document/List"));
const MyActivity = React.lazy(() => import("./MyActivity/MyActivity"));

const ToggleLotIsFavorite = React.lazy(
  () => import("./Common/ToggleLotIsFavorite/ToggleLotIsFavorite"),
);

const ChatBoxButton = React.lazy(() => import("./Common/Company/ChatBoxButton"));
const ChatMessages = React.lazy(() => import("./Profile/ChatMessages/ChatMessages"))

const LoginWithCert = React.lazy(() => import("./Common/LoginWithCert"));

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Watermarks from "./Profile/Details/Watermarks";

//const searchContainer = document.getElementById("search_list");
//if (searchContainer) {
//    ReactDOM.render(<React.Suspense fallback={<div className="loading show"></div>}>
//        <Search query={searchContainer.getAttribute('data-query')}
//            categoryId={Number(searchContainer.getAttribute('data-category-id'))} />
//    </React.Suspense>,
//        searchContainer);
//}

//const SCCloseRowItem = React.lazy(() => import("./Common/SCcloseRowItem/SCcloseRowItem"));
//const SCCloseRowItemContainer = document.getElementById("SCcloseRowItem");
//if (SCCloseRowItemContainer) {
//    const username = SCCloseRowItemContainer.getAttribute("data-attribute-userName");
//    ReactDOM.render(
//        <React.Suspense fallback={<div className="loading show" />}>
//            <SCCloseRowItem userName={username}/>
//        </React.Suspense>,
//        SCCloseRowItemContainer,
//    );
//}

const mainSearchContainer = document.getElementById("main_search");
if (mainSearchContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <MainSearch />
    </React.Suspense>,
    mainSearchContainer,
  );
}

const pageNotifications = document.getElementById("notifications");
if (pageNotifications) {
  ReactDOM.render(

    <React.Suspense fallback={<div className="loading show"></div>}>
      <Notifications />
    </React.Suspense>,
    pageNotifications,
  );
}

const pagePromotionServices = document.getElementById("promotion-services");
if (pagePromotionServices) {
  ReactDOM.render(

    <React.Suspense fallback={<div className="loading show"></div>}>
      <PromotionServices />
    </React.Suspense>,
    pagePromotionServices,
  );
}


const pageRatingLimit = document.getElementById("rating-limit");
if (pageRatingLimit) {
  ReactDOM.render(

    <React.Suspense fallback={<div className="loading show"></div>}>
      <RatingLimit />
    </React.Suspense>,
    pageRatingLimit,
  );
}



const pageOperatinosHistory = document.getElementById("operations-history");
if (pageOperatinosHistory) {
  ReactDOM.render(

    <React.Suspense fallback={<div className="loading show"></div>}>
      <OperatinosHistory />
    </React.Suspense>,
    pageOperatinosHistory,
  );
}


const MessageToBuyersContainer = document.getElementById("message-to-buyers");
if (MessageToBuyersContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <MessageToBuyers />
    </React.Suspense>,
    MessageToBuyersContainer,
  );
}

const categoryLotsListContainer = document.getElementById("category_lots_list");
if (categoryLotsListContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <CategoryLotsList
        categoryId={Number(
          categoryLotsListContainer.getAttribute("data-category-id"),
        )}
        companyId={Number(
          categoryLotsListContainer.getAttribute("data-company-id"),
        )}
        banner={categoryLotsListContainer.getAttribute("data-category-banner")}
        query={categoryLotsListContainer.getAttribute("data-query")}
        isBuyNow={
          parseInt(categoryLotsListContainer.getAttribute("data-isBuyNow")) == 1
        }
        phraseType={parseInt(
          categoryLotsListContainer.getAttribute("data-phraseType"),
        )}
        targetType={parseInt(
          categoryLotsListContainer.getAttribute("data-targetType"),
              )}
              countryId={parseInt(categoryLotsListContainer.getAttribute("data-country")) }
        regionId={parseInt(
          categoryLotsListContainer.getAttribute("data-region"),
        )}
        currentPage={parseInt(
          categoryLotsListContainer.getAttribute("data-page"),
        )}
        sort={categoryLotsListContainer.getAttribute("data-sort")}
        tradeType={parseInt(
          categoryLotsListContainer.getAttribute("data-tradeType"),
              )}
        auctionType={parseInt(
            categoryLotsListContainer.getAttribute("data-auctionType"),
        )}
        timeSearch={parseInt(
          categoryLotsListContainer.getAttribute("data-timeSearch"),
        )}
        startPrice={categoryLotsListContainer.getAttribute("data-start-price")}
        endPrice={categoryLotsListContainer.getAttribute("data-end-price")}
        startDate={categoryLotsListContainer.getAttribute("data-start-dat")}
        endDate={categoryLotsListContainer.getAttribute("data-end-date")}
        companyName={categoryLotsListContainer.getAttribute(
          "data-company-name",
        )}
      />
    </React.Suspense>,
    categoryLotsListContainer,
  );
}

const chatBoxContainer = document.getElementById("chat-box-container");
if(chatBoxContainer){
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"/>}>
            <ChatBoxButton
                currentAccountId={chatBoxContainer.getAttribute("data-current-user-id")}
                lotId={chatBoxContainer.getAttribute("data-lot-id")}
                sellerName={chatBoxContainer.getAttribute("data-seller-name")} 
                sellerAvatar={chatBoxContainer.getAttribute("data-seller-avatar")}/>
        </React.Suspense>,
        chatBoxContainer,
    );
}


const chatMessagesContainer = document.getElementById("chat_messages");
if (chatMessagesContainer) {
    ReactDOM.render( 
        <React.Suspense fallback={<div className="loading show"></div>}>
            <ChatMessages   lotId={chatMessagesContainer.getAttribute("data-lot-id")}
                            currentAccountId={chatMessagesContainer.getAttribute("data-current-user-id")}
                            // sellerName={chatMessagesContainer.getAttribute("data-seller-name")}
                            sellerAvatar={chatMessagesContainer.getAttribute("data-seller-avatar")}/>
        </React.Suspense>,
        chatMessagesContainer,
    );
}

//const lotsListContainer = document.getElementById("lots_list");
//if (lotsListContainer) {
//    ReactDOM.render(<React.Suspense fallback={<div className="loading show"></div>}>
//        <LotsList filters={JSON.parse(lotsListContainer.getAttribute('data-filters'))} />
//        </React.Suspense>,
//        lotsListContainer);
//}

//const lotEditContainer = document.getElementById("lot_edit");
//if (lotEditContainer) {
//    ReactDOM.render(<React.Suspense fallback={<div className="loading show"></div>}>
//        <LotEdit lotId={Number(lotEditContainer.getAttribute('data-lot-id'))} validate={lotEditContainer.getAttribute('data-validate') == "true"}/>
//        </React.Suspense>,
//        lotEditContainer);
//}

const lotEditorContainer = document.getElementById("lot_editor");
if (lotEditorContainer) {
  ReactDOM.render(
      <React.Suspense fallback={<div className="loading show"></div>}>
          <LotsEditor
              lotId={Number(lotEditorContainer.getAttribute("data-lot-id"))}
              validate={lotEditorContainer.getAttribute("data-validate") == "true"}
              isEdit={lotEditorContainer.getAttribute("data-is-edit") == "true"}
          />
      </React.Suspense>,
    lotEditorContainer
  );
}

const loginWithCert = document.getElementById("login_with_cert_container");
if (loginWithCert) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <LoginWithCert />
        </React.Suspense>,
        loginWithCert,
    );
}



const profileContainer = document.getElementById("profile_container");
if (profileContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <ProfileDetailsEditor />
    </React.Suspense>,
    profileContainer,
  );
}


let target = document.querySelector('header');
const config = {
  childList: true,
  subtree: true
};

const callback = function() {
  const watermarkContainer = document.getElementById('watermark-id')
  if (watermarkContainer) {
    ReactDOM.render(
      <React.Suspense fallback={<div className="loading show"></div>}>
        <Watermarks />
      </React.Suspense>,
      watermarkContainer,
    );
  }
};
const observer = new MutationObserver(callback);
observer.observe(target, config);




const rateContainer = document.getElementById("rate_container");
if (rateContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <Rate />
    </React.Suspense>,
    rateContainer,
  );
}

const appealContainer = document.getElementById("appeal_container");
if (appealContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <Appeal />
    </React.Suspense>,
    appealContainer,
  );
}


const lotDocumentContainer = document.getElementById(
    "lot_document_container",
);
if (lotDocumentContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <LotDocument lotId={Number(lotDocumentContainer.getAttribute("lotId"))} />
        </React.Suspense>,
        lotDocumentContainer,
    );
}

const lotDocumentsContainer = document.getElementById(
    "lot_documents_container",
);
if (lotDocumentsContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <DocumentsList loadUrl={"/TradeLotActionsApi/GetLotDocuments"} refresh={true} lotId={Number(lotDocumentsContainer.getAttribute("lotId"))} />
        </React.Suspense>,
        lotDocumentsContainer,
    );
}

const lotWinnerProtocolContainer = document.getElementById(
    "lot_winner_protocol_container",
);
if (lotWinnerProtocolContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <LotWinnerProtocol lotId={Number(lotWinnerProtocolContainer.getAttribute("lotId"))} />
        </React.Suspense>,
        lotWinnerProtocolContainer,
    );
}

const profileDocumentsContainer = document.getElementById(
  "profile_documents_container",
);
if (profileDocumentsContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <ProfileDocumentsEditor />
    </React.Suspense>,
    profileDocumentsContainer,
  );
}

const vacationContainer = document.getElementById("vacation_container");
if (vacationContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <VacationDetailsEditor />
    </React.Suspense>,
    vacationContainer,
  );
}

const deliveryAdressContainer = document.getElementById(
  "deliveri_adress_container",
);
if (deliveryAdressContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <DeliveryAdressDetailsEditor />
    </React.Suspense>,
    deliveryAdressContainer,
  );
}

const blackListSellersContainer = document.getElementById(
  "black_list_sellers_container",
);
if (blackListSellersContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <BlackListSellersDetailsEditor />
    </React.Suspense>,
    blackListSellersContainer,
  );
}
const blackListBuyersContainer = document.getElementById(
  "black_list_buyers_container",
);
if (blackListBuyersContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <BlackListBuyersDetailsEditor />
    </React.Suspense>,
    blackListBuyersContainer,
  );
}
const locationlotContainer = document.getElementById("location_lot_container");
if (locationlotContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <LocationLotDetailsEditor />
    </React.Suspense>,
    locationlotContainer,
  );
}
const standartDescriptionLotContainer = document.getElementById(
  "description_lot_container",
);
if (standartDescriptionLotContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <StandartDescriptionLotEditor />
    </React.Suspense>,
    standartDescriptionLotContainer,
  );
}

const balanceContainer = document.getElementById("balans_container");
if (balanceContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <BalanceEditor />
    </React.Suspense>,
    balanceContainer,
  );
}

const documentsListContainer = document.getElementById("documents_list");
if (documentsListContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
          <DocumentsList
        lotId={Number(documentsListContainer.getAttribute('data-lotId')) }
        loadUrl={documentsListContainer.getAttribute("data-load-url")}
        refresh={false}
      />
    </React.Suspense>,
    documentsListContainer,
  );
}

const myActivityContainer = document.getElementById("my_activity");
if (myActivityContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <BrowserRouter>
        <MyActivity />
      </BrowserRouter>
    </React.Suspense>,
    myActivityContainer,
  );
}

const toggleLotIsFavoriteContainers =
  document.getElementsByClassName("favourites-toggle");

if (toggleLotIsFavoriteContainers && toggleLotIsFavoriteContainers.length > 0) {
  [...toggleLotIsFavoriteContainers].map((container) =>
    ReactDOM.render(
      <React.Suspense fallback={<div></div>}>
        <ToggleLotIsFavorite
          lotId={Number(container.getAttribute("data-lotId"))}
          curUserFavorite={
            container.getAttribute("data-curUserFavorite").toLowerCase() ==
            "true"
          }
          userLoggedIn={
            container.getAttribute("data-userLoggedIn").toLowerCase() == "true"
          }
          showText={
            container.getAttribute("data-showText").toLowerCase() == "true"
          }
          type={container.getAttribute("data-fav-type")}
        />
      </React.Suspense>,
      container,
    ),
  );
}

const myMessagesContainer = document.getElementById("my_messages");
if (myMessagesContainer) {
  ReactDOM.render(
    <React.Suspense fallback={<div className="loading show"></div>}>
      <AccountMessages />
    </React.Suspense>,
    myMessagesContainer,
  );
}

const popupBuyNowContainer = document.getElementById("popupBuyNow");
if (popupBuyNowContainer) {
    ReactDOM.render(
        <React.Suspense fallback={<div className="loading show"></div>}>
            <PopupBuyNow
                lotId={popupBuyNowContainer.getAttribute("data-lotId")}
                isTradeEnd={popupBuyNowContainer.getAttribute("data-isTradeEnd")}
            />
        </React.Suspense>,
        popupBuyNowContainer
    );
}

const accountInfo = document.getElementById("accountInfo");

if (accountInfo) {
    AccountInfo.Thumbprint = accountInfo.getAttribute('data-thumbprint');
    AccountInfo.Id = Number(accountInfo.getAttribute('data-id'));
    AccountInfo.Fio = accountInfo.getAttribute('data-fio');
    AccountInfo.IsAdminRole = accountInfo.getAttribute('data-isAdminRole');
    AccountInfo.CurrentUserCanSign = Boolean(accountInfo.getAttribute('data-currentUserCanSign'));
    AccountInfo.init();
    console.log('AccountInfo: ', AccountInfo);
}

//const subscriptionContainers = document.getElementsByClassName("subscribe_to_company");
//console.log("subscribe_to_company", subscriptionContainers);
//if (subscriptionContainers && subscriptionContainers.length > 0) {
//    [...subscriptionContainers].map(subscriptionContainer =>
//        ReactDOM.render(<React.Suspense fallback={<div className="loading show"></div>}>
//                            <Subscription userSubscribed={(subscriptionContainer.getAttribute('data-user-subscribed') == "true"
//                    )}
//                                          userId={Number(subscriptionContainer.getAttribute('data-user-id'))}
//                                          companyId={Number(subscriptionContainer.getAttribute('data-company-id'))}/>
//                        </React.Suspense>,
//            subscriptionContainer));
//}

//const diffWithCurDateContainers = document.getElementsByClassName("diff_with_cur_date");
//if (diffWithCurDateContainers && diffWithCurDateContainers.length > 0) {
//    [...diffWithCurDateContainers].map(diffWithCurDateContainer =>
//        ReactDOM.render(<React.Suspense fallback={<div className="loading show"></div>}>
//            <DiffWithCurrentDate date={new Date(diffWithCurDateContainer.getAttribute('data-date')) }/>
//         </React.Suspense>,
//        diffWithCurDateContainer));
//}

//const companyReviewsContainers = document.getElementById("list_reviews_for_company");
//if (companyReviewsContainers) {
//    ReactDOM.render(<React.Suspense fallback={<div className="loading show"></div>}>
//        <ReviewsList companyId={Number(companyReviewsContainers.getAttribute('data-company-id'))}
//            companyName={companyReviewsContainers.getAttribute('data-company-name')}
//            currentUserCanReview={(companyReviewsContainers.getAttribute('data-current-user-can-review') == "true")} />
//                    </React.Suspense>,
//        companyReviewsContainers);
//}

//const favoritesContainers = document.getElementById("user_favorites");
//if (favoritesContainers) {
//    ReactDOM.render(<React.Suspense fallback={<div className="loading show"></div>}>
//                        <UserFavorites />
//                    </React.Suspense>,
//        favoritesContainers);
//}

//const toggleLotIsFavoriteContainers = document.getElementById("lot_is_favorite_toggle");
//if (toggleLotIsFavoriteContainers) {
//    ReactDOM.render(<React.Suspense fallback={<div className="loading show"></div>}>
//        <ToggleLotIsFavorite lotId={Number(toggleLotIsFavoriteContainers.getAttribute('data-lotId'))}
//                            curUserFavorite={toggleLotIsFavoriteContainers.getAttribute('data-curUserFavorite') == "true"}
//                            userLoggedIn={(toggleLotIsFavoriteContainers.getAttribute('data-userLoggedIn') == "true")}
//                            showText={(toggleLotIsFavoriteContainers.getAttribute('data-showText') == "true")} />
//                    </React.Suspense>,
//        toggleLotIsFavoriteContainers);
//}

console.log("Realty branch");
/* eslint-disable prettier/prettier */
import { DocumentTypes } from "../Common/types";
import Fetcher from "../Common/Fetch";

class AccountInfo  {
    constructor() { }

    Id: 0;
    Fio: "";
    IsAdminRole: false;
    Thumbprint: "";
    CurrentUserCanSign: false;
    ListDocuments: { Id: 0, DocumentType: DocumentType, IsSignature: false };
    PaymentLots: [{ AccountId: 0, LotId: 0, StatusId: 0 }]

    init() {

        if (this.Id > 0)
            Fetcher('/ProfileApi/GetPaymentLots/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    this.PaymentLots = data;
            });
    }

}
export default new AccountInfo();